/* Figtree front from Google Fonts */
/* Downloaded from the google-webfonts-helper: https://gwfh.mranftl.com/fonts/figtree?subsets=latin */

/* figtree-regular - latin */
@font-face {
  font-display: swap; /* Optimize loading behavior */
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/figtree/figtree-v6-latin-regular.woff2') format('woff2'); /* Modern browsers */
}
/* figtree-italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/figtree/figtree-v6-latin-italic.woff2') format('woff2');
}
/* figtree-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/figtree/figtree-v6-latin-500.woff2') format('woff2');
}
/* figtree-500italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/figtree/figtree-v6-latin-500italic.woff2') format('woff2');
}
/* figtree-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/figtree/figtree-v6-latin-600.woff2') format('woff2');
}
/* figtree-600italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 600;
  src: url('assets/fonts/figtree/figtree-v6-latin-600italic.woff2') format('woff2');
}
/* figtree-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/figtree/figtree-v6-latin-700.woff2') format('woff2');
}
/* figtree-700italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/figtree/figtree-v6-latin-700italic.woff2') format('woff2');
}
/* figtree-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/figtree/figtree-v6-latin-800.woff2') format('woff2');
}
/* figtree-800italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 800;
  src: url('assets/fonts/figtree/figtree-v6-latin-800italic.woff2') format('woff2');
}
/* figtree-900 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/figtree/figtree-v6-latin-900.woff2') format('woff2');
}
/* figtree-900italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 900;
  src: url('assets/fonts/figtree/figtree-v6-latin-900italic.woff2') format('woff2');
}

/* Custom Properties */
:root {
  --color-body-background: #F4F3F2;
  --color-bright-blue: #0060A8;
  --color-light-blue:  #EAEEF6;
  --color-background-blue: #D3E4FF;
  --color-light-gray: #DDE3EA;
  --color-pillbox-gray: #C0C7CD;
  --color-code-background: #D3E0EE;
  --color-black: #41484D;
  --color-white: #FCFBFB;
  --color-error: #881C22;
  --font-family-primary: 'Figtree', sans-serif;
  --font-family-monospace: 'Courier New', Courier, monospace;
}

/* Global tag styles */
body {
  background-color: var(--color-body-background);
  font-family: var(--font-family-primary);
  color: var(--color-black);
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

a {
  color: var(--color-bright-blue);
  text-decoration: underline;
  cursor: pointer;
}

strong {
  font-weight: 700;
}

/* Override 'em' tag for hit-highlighting display */
em {
  font-style: normal; /* Remove italics */
  font-weight: 800;   /* Apply bold */
}

/* Override 'b' tag for AI Sources display */
b {
  font-weight: 700;;
}

pre {
  display: block;
  padding: 10px;
  background-color: var(--color-code-background);
  color: var(--color-black);
  font-family: var(--font-family-monospace);
  font-weight: 700;
  line-height: 1.5;
  overflow-x: auto;
}

code {
  background-color: var(--color-code-background);
  color: var(--color-black);
  font-family: var(--font-family-monospace);
  font-weight: 700;
}

/* Login, Logout, Lockout styles */
.login-container {
  height: 100vh; /* Full viewport height */
  max-width: 40%; /* Percentage of the viewport width */
  width: 100%;
  margin: 0 auto; /* Center horizontally */
  background-color: var(--color-white); /* Whiter background for contrast */
  overflow: hidden; /* Prevent scrolling */
}

.login-card {
  max-width: 65%; /* Percentage of the container width */
  width: 100%; /* Full width within the container */
  padding: 2rem; /* Add padding for spacing inside the card */
  background-color: var(--color-white); /* Match container color for seamless look */
}

.login-card .d-grid {
  margin-top: 4rem; /* Space between credentials and button */
}

.login-card input {
  background-color: var(--color-white);
}

.login-logo {
  background: url('/api/swirl/sapi/branding/?target=light') center center / contain no-repeat;
  height: 12vw;
  width: 12vw;
  display: block;
  margin: 0 auto; /* Center the logo */
  margin-bottom: 4rem; /* Space between logo and credentials */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.login-form-label {
  font-size: 0.75rem;
  font-weight: 700;
}

.login-oidc {
  margin-bottom: 3rem; /* Spacing below OIDC button */
}

.oidc-button {
  display: flex; /* Flexbox for aligning icon and text */
  align-items: center; /* Center icon and text vertically */
  justify-content: center; /* Center content horizontally */
  gap: 0.5rem; /* Add space between the icon and the text */
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 2rem;
}

.oidc-icon {
  width: 1rem; /* Set a fixed width for the icon */
  height: 1rem; /* Set a fixed height for the icon */
  display: inline-block; /* Ensure it's treated as an inline element */
  vertical-align: middle; /* Align icon with text */
}

.login-button {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 2rem;
}

.error-text {
  color: var(--color-error)
}

/* Ensure navbar content respects container padding */
.container-fluid {
  max-width: 1920px; /* Constrain to desired max width */
  margin: 0 auto; /* Center the content */
  padding: 0;
}

/* Global navbar styles */
.navbar {
  background-color: var(--color-white);
  margin: 0;
  padding-top: 6px; /* Top padding */
  padding-bottom: 24px; /* Bottom padding */
  box-sizing: border-box;
}

/* Logo Styles */
.navbar-brand {
  display: flex; /* Ensure the logo is centered */
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo-img {
  max-height: 100px; /* Dynamically control height */
  max-width: 100%; /* Ensure it doesn't exceed parent width */
  height: auto; /* Maintain aspect ratio */
}

/* Profile Icon */
.profile-icon {
  width: 37px;
  height: 30px;
  object-fit: cover; /* Prevent distortion */
  display: block; /* Ensures no inline spacing issues */
  margin: 0; /* Remove image margin */
  padding: 0; /* Remove image padding */
}

#profileDropdown {
  background: none; /* Removes background hover */
  border: none; /* Removes border */
  box-shadow: none; /* Removes any shadow effects */
  margin: 0; /* Remove button margin */
  padding: 0; /* Remove button padding */
}

#profileDropdown::after {
  display: none; /* Hides the default Bootstrap caret */
}

/* Global button styles */
.btn-primary {
  background-color: var(--color-bright-blue);
  color: var(--color-white)
}

/* Ensure no hover effect by making these the same as the button itself */
.btn-primary:hover { 
  background-color: var(--color-bright-blue);
  color: var(--color-white);
}

.btn-link {
  color: var(--color-bright-blue);
}

/* Global checkbox and toggle styles */
.form-check-input:checked {
  background-color: var(--color-bright-blue);
  border-color: var(--color-bright-blue);
}

/* Global Dropdown Menu Styles */
/* Menus open */
.dropdown-menu {
  background-color: var(--color-light-blue);
  border: 5px solid var(--color-white);
  overflow-y: auto;
  border-radius: 5px;
}

/* Menu items */
.dropdown-menu .dropdown-item {
  background-color: transparent;
  cursor: pointer;
  font-size: 0.875rem;
}

/* Menu items selected */
.dropdown-menu .form-check-input:checked + .form-check-label,
.dropdown-menu .dropdown-item.selected {
  background-color: transparent;
  font-weight: 600;
}

/* Menu carets */
.dropdown-toggle {
  display: flex; /* Flexbox for icon and text alignment */
  justify-content: space-between; /* Push text left and arrow right */
  align-items: center; /* Center text and icon vertically */
  font-size: 0.875rem;
  color: var(--color-bright-blue);
}

/* Global Custom Toggle Styles */
/* Toggle container */
.custom-toggle {
  display: flex; /* Enable flexbox */
  align-items: center; /* Vertically center the toggle and label */
  gap: 0.25rem; /* Add space between the toggle and the label */
  border: none; /* Remove any potential border styling from Bootstrap */
}
/* Toggle switch */
.custom-toggle .form-check-input {
  width: 2.5rem; /* Larger toggle width */
  height: 1.5rem; /* Larger toggle height */
  border-radius: 1.5rem; /* Keep toggle circular */
  transition: all 0.2s ease-in-out; /* Smooth transitions for state changes */
  margin-top: 0; /* Reset default margins */
  margin-bottom: 0; /* Prevent toggle from misaligning */
  box-shadow: none; /* Remove default focus shadow */
  outline: none; /* Remove focus outline */
}
/* Remove focus shadow when active (on click) */
.custom-toggle .form-check-input:active {
  box-shadow: none;
  outline: none;
}
/* Toggle switch - enabled */
.custom-toggle .form-check-input:checked::before {
  width: 1.25rem; /* Thumb size */
  height: 1.25rem;
  transform: translateX(1rem); /* Position the thumb to the right */
}
/* Toggle label */
.custom-toggle .form-check-label {
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0; /* Reset default margins */
  line-height: 1.5rem; /* Match the toggle's height for vertical alignment */
  white-space: nowrap; /* Prevent the label from wrapping */
}

/* AI Follow-Up Question Styles */
/* Header */
.question-header {
  font-weight: 700;
  font-style: italic;
  margin-bottom: 0.5rem;
}

/* Container for scoping */
.question-list ul {
  list-style: none; /* Removes default bullets */
  padding-left: 0; /* Aligns the list flush with its container */
  font-size: 0.875rem;
}

.question-list-item {
  background-color: var(--color-white);
  padding: 0.25rem 0.5rem; /* Adds padding for spacing */
  margin-bottom: 0.5rem; /* Adds space between items */
  display: flex; /* Ensures consistent alignment of bullet and text */
  align-items: center; /* Vertically centers content */
  border: 1px solid var(--color-light-gray);
}

.question-list-item::before {
  content: ">"; /* Replaces default bullet with '>' */
  margin-right: 0.5rem; /* Adds space between the bullet and text */
  color: var(--color-bright-blue);
}

.question-link {
  cursor: pointer;
}

/* AI Sources Styles */
/* Header */
.sources-header {
  font-style: italic;
  font-weight: 700;
}

/* Container for scoping */
.sources-list-ul {
  list-style: none; /* Removes default bullets */
  padding-left: 0; /* Aligns the list flush with its container */
  font-size: 0.875rem;
  display: flex; /* Makes the list items align horizontally */
  flex-wrap: wrap; /* Wrap items to the next line if they don't fit */
  gap: 0.5rem; /* Adds spacing between the boxes */
  margin: 0; /* Reset any default margins */
}

/* Style for list items */
.sources-list-li {
  position: relative; /* Allows positioning the favicon within the box */
  background-color: var(--color-white); /* Box background */
  padding: 0.5rem 1rem; /* Adds padding inside the box */
  display: flex; /* Flex layout for better control of content */
  align-items: center; /* Vertically aligns content */
  flex-wrap: wrap; /* Ensures text wraps properly */
  flex: 0 0 calc(25% - 1rem); /* Fixed width for four items per row */
  max-width: calc(25% - 1rem); /* Prevent items from exceeding set width */
  box-sizing: border-box; /* Include padding and borders in width */
  text-align: left; /* Aligns link text to the left */
  border: 1px solid var(--color-light-gray);
  padding-right: 2rem; /* Ensures there is enough space for the favicon */
}

/* Links */
.sources-list-link {
  display: inline-block; /* Ensure link text behaves like normal text */
  cursor: pointer;
  text-align: left; /* Aligns text to the left */
  word-break: break-word; /* Ensures long words wrap */
  width: calc(100% - 2rem); /* Reserve space for the favicon */
}

/* Favicons */
.source-favicon {
  position: absolute; /* Positions the favicon relative to the list item */
  bottom: 0.5rem; /* Offset from the bottom */
  right: 0.5rem; /* Offset from the right */
  width: 16px; /* Standard favicon size of 16x16 */
  height: 16px;
  object-fit: contain; /* Prevent distortion */
  padding: 0.25rem; /* Adds spacing around the favicon */
  box-sizing: content-box; /* Ensures padding does not reduce the favicon size */
  filter: grayscale(100%); /* Converts the favicon to grayscale */
  pointer-events: none; /* Ensure the favicon doesn't interfere with link clicks */
  z-index: 1; /* Keeps the favicon above other elements */
}

/* Global CookieConsent Styles */
.cc-window {
  font-family: var(--font-family-primary);
  background-color: var(--color-bright-blue);
  color: var(--color-white);
}
  .cc-window .cc-link {
    font-family: var(--font-family-primary);
    color: var(--color-white);
    text-decoration: underline;
  }

.cc-message {
  font-family: var(--font-family-primary);
  color: var(--color-white);
}

.cc-btn {
  font-family: var(--font-family-primary);
  background-color: var(--color-white);
  color: var(--color-black);
  border: transparent;
}

.cc-compliance {
  display: flex;
  justify-content: flex-end; /* Ensure buttons are aligned to the right */
}
